import { useFormikContext } from 'formik';
import { TextInput } from 'grommet';
import React, { useEffect, useRef, useState } from 'react';
import { FormValues } from '.';
import FormField from './FormField';

interface Props {
  label?: string;
  placeholder?: string;
  type?: 'text' | 'password';
  name: string;
  required?: boolean;
}

const Input: React.FC<Props> = ({ name, label, placeholder, type = 'text', required }) => {
  const { values, setFieldValue, submitForm } = useFormikContext<FormValues>();
  const [internalValue, setInternalValue] = useState(values[name] || '');
  const [submit, setSubmit] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      setFieldValue(name, ref.current?.value);
      setInternalValue(ref.current?.value || '');
      setSubmit(true);
    }
  };
  useEffect(() => {
    if (submit) {
      setSubmit(false);
      submitForm();
    }
  }, [submit, submitForm]);
  return (
    <FormField name={name} label={label} required={required}>
      <TextInput
        ref={ref as never}
        name={name}
        placeholder={placeholder}
        value={internalValue}
        onChange={e => setInternalValue(e.target.value)}
        onBlur={() => setFieldValue(name, internalValue)}
        type={type}
        onKeyPress={handleKeyPress}
      />
    </FormField>
  );
};

export default Input;
