import React from 'react';
import { RouteComponentProps } from 'react-router';
import RestrictedContent from '../components/restrictedcontent';
import GalleriesContent from '../components/galleriescontent';

const Page: React.FC<RouteComponentProps> = props => {
  return (
    <RestrictedContent>
      <GalleriesContent galleryType={props.match.path === '/member/photos' ? 'PHOTO' : 'VIDEO'} />
    </RestrictedContent>
  );
};

export default Page;
