import { ThemeType } from 'grommet';
import { css } from 'styled-components';

const theme: ThemeType = {
  global: {
    font: {
      family: 'Roboto',
    },
    colors: {
      brand: '#850200',
      'status-error': '#cc0000',
      'status-ok': '#00cc00',
      'status-warning': '#cccc00',
      text: '#666666',
      focus: '#ffcccc',
    },
    control: {
      border: {
        radius: '5px',
      },
    },
    input: {
      font: {
        weight: 400,
      },
    },
  },
  button: {
    border: {
      radius: '5px',
    },
    primary: {
      extend: css`
        color: #850200;
        background: #dfdfdf;
        padding: 10px 20px;
        &:hover {
          background: #efefef;
        }
        &:active {
          background: #cfcfcf;
        }
        &:focus {
          box-shadow: 0 0 0 2px #850200 !important;
        }
      `,
    },
    extend: css`
      font-family: Roboto;
    `,
  },
  select: {
    background: 'white',
    control: {
      extend: css`
        width: 100%;
        & > div {
          border-radius: 5px;
        }
      `,
    },
  },
  textInput: {
    extend: css`
      background: #ffffff;
      color: #666666;
      font-family: Roboto;
    `,
  },
  layer: {
    zIndex: '9999',
    background: 'none',
    overlay: {
      background: {
        color: '#000000',
        opacity: 'strong',
      },
    },
    container: {
      zIndex: '9999',
    },
  },
};

export default theme;
