import React from 'react';
import Layout from '../restricted/layout';
import { isEmpty } from 'util/objects';
import { useUser } from 'globalState';
import { useHistory } from 'react-router';

const Component: React.FC = ({ children }) => {
  const user = useUser();
  const { push } = useHistory();
  if (isEmpty(user?.subscriptionType)) {
    push('/member/account');
    return null;
  }
  return <Layout>{children}</Layout>;
};

export default Component;
