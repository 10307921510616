import { api } from '../services';

let renewTokenTimer = false;

export const renewToken = (): void => {
  if (renewTokenTimer !== true) {
    renewTokenTimer = true;
    setTimeout(
      () =>
        api.renew().then(dto => {
          window.localStorage.setItem('token', dto.token);
          renewTokenTimer = false;
          renewToken();
        }),
      1000 * 60 * 3
    );
  }
};
