import React from 'react';
import styled from 'styled-components';
import { ImageData } from '../../model';
import { chunk } from 'lodash';
import LazyLoadingRow from './LazyLoadingRow';

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  images: ImageData[];
  showType: boolean;
  onClick?: (image: ImageData) => void;
}

const Gallery: React.FC<Props> = ({ images, showType, onClick }) => {
  const rows: ImageData[][] = chunk(images, 4);
  return (
    <GalleryContainer>
      {rows.map((row, i) => (
        <LazyLoadingRow key={i} images={row} showType={showType} onClick={onClick} />
      ))}
    </GalleryContainer>
  );
};

export default Gallery;
