import { CmsGiftGalleryDto } from 'dtos';
import { useEffect, useState } from 'react';
import { api } from 'services';

interface UseDbGalleryResult {
  gallery: CmsGiftGalleryDto | undefined;
  loading: boolean;
}

const useDbGallery = (name: string): UseDbGalleryResult => {
  const [gallery, setGallery] = useState<CmsGiftGalleryDto>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    api
      .getGiftGallery(name)
      .then(setGallery)
      .finally(() => setLoading(false));
  }, [name]);
  return { gallery, loading };
};

export default useDbGallery;
