import { Formik } from 'formik';
import { flatMap, toPairs } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FormError from './FormError';

export type FormValues = Record<string, string>;

export type FormErrors = Record<string, string[]>;

export type ValidationRule = (values: FormValues) => FormErrors;

const StyledForm = styled.form`
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: #404040;
`;

interface Props {
  onSubmit: (values: FormValues) => void;
  validationRules?: ValidationRule[];
  errorMessage?: string;
}

const Form: React.FC<Props> = ({ onSubmit, validationRules = [], errorMessage, children }) => {
  const validate = (values: FormValues): FormErrors => {
    const errors: FormErrors = {};
    flatMap(validationRules.map(rule => toPairs(rule(values)))).forEach(([key, value]) => {
      const existing = errors[key];
      errors[key] = existing === undefined ? value : existing.concat(value);
    });
    return errors;
  };
  return (
    <Formik initialValues={{}} onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormError errorMessage={errorMessage} />
          {children}
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;
