import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  font-family: 'Lora', Serif;
  font-size: 10pt;
  color: #efefef;
`;

interface Props {
  children?: React.ReactNode;
}

const Text: React.FC<Props> = (props: Props) => {
  return <Paragraph>{props.children}</Paragraph>;
};

export default Text;
