import { MessageDto } from '../../dtos';

interface Node {
  message: MessageDto;
  replies: Node[];
}

const collectMessages: (nodes: Node[], result: MessageDto[]) => void = (nodes, result) => {
  nodes.forEach(n => {
    result.push(n.message);
    collectMessages(n.replies, result);
  });
};

export const orderMessages: (messages: MessageDto[]) => MessageDto[] = messages => {
  const nodes: Node[] = messages.map(msg => ({ message: msg, replies: [] }));
  const rootNodes: Node[] = nodes.filter(n => !n.message.parentId);
  const nodeById: Node[] = [];
  nodes.forEach(n => (nodeById[n.message.id] = n));
  nodes
    .filter(n => !!n.message.parentId)
    .forEach(n => {
      nodeById[n.message.parentId].replies.push(n);
    });
  const result: MessageDto[] = [];
  collectMessages(rootNodes, result);
  return result;
};
