import React, { useState } from 'react';
import styled from 'styled-components';
import useDbGallery from './useDbGallery';
import Lightbox from 'components/Lightbox';

interface Props {
  name: string;
}

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: 1px solid transparent;
  box-sizing: border-box;
  &:hover {
    border: 1px solid white;
  }
`;

const ThumbnailContainer = styled.div`
  margin: 10px;
  width: 222px;
  height: 222px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const imageUrl = (path: string) => `/api/cms${path}`;

const DbGallery: React.FC<Props> = ({ name }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const { gallery } = useDbGallery(name);

  const showImage = (i: number, e?: React.MouseEvent<HTMLDivElement>) => {
    if (e) {
      e.preventDefault();
    }
    setCurrentImage(i);
    setLightboxOpen(true);
  };

  return (
    <GalleryContainer>
      {gallery &&
        gallery.images.map((img, i) => (
          <ThumbnailContainer key={i}>
            <Wrapper onClick={e => showImage(i, e)}>
              <Image src={imageUrl(img.image.formats.thumbnail.url)} />
            </Wrapper>
          </ThumbnailContainer>
        ))}
      <Lightbox
        images={
          (gallery &&
            gallery.images.map(img => ({
              url: imageUrl(img.image.formats.large.url),
              description: img.description,
            }))) ||
          []
        }
        index={currentImage}
        open={lightboxOpen}
        onIndexChange={setCurrentImage}
        onClose={() => setLightboxOpen(false)}
      />
    </GalleryContainer>
  );
};

export default DbGallery;
