import React, { useEffect, useState } from 'react';
import { useCountries } from 'globalState';
import { Select } from 'grommet';
import FormField from './FormField';
import { useFormikContext } from 'formik';
import { FormValues } from '.';
import { CountryDto } from 'dtos';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
}

const Country: React.FC<Props> = ({ name, label, required }) => {
  const countries = useCountries();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [internalValue, setInternalValue] = useState<CountryDto>();
  const value = values[name];
  useEffect(() => {
    setInternalValue(countries.find(c => c.code === value));
  }, [countries, value]);
  return (
    <FormField name={name} label={label} required={required}>
      <Select
        options={countries}
        value={internalValue}
        labelKey="name"
        valueKey="code"
        onChange={e => setFieldValue(name, e.value?.code)}
      />
    </FormField>
  );
};

export default Country;
