import Icon from 'components/Icon';
import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 26px;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  font-size: 10pt;
  > input:checked + span {
    color: #777777;
  }
`;

const Input = styled.input`
  outline: none;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
`;

const Indicator = styled.span`
  background-color: #ffffff;
  border: 2px solid #999999;
  color: #ffffff;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
`;

interface Props {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
}

const Checkbox: React.FC<Props> = props => (
  <Container>
    <Input type="checkbox" name={props.name} value={props.value} />
    <Indicator>
      <Icon type="check" />
    </Indicator>
    {props.label}
  </Container>
);

export default Checkbox;
