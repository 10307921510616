import React, { useState } from 'react';
import Layout from './layout';
import { Form, Input, Section, Link } from '../../components';
import { api } from '../../services';
import styled from 'styled-components';
import { renewToken } from 'util/auth';
import { useHistory } from 'react-router';
import { useTokenState } from 'globalState';
import { FormValues } from 'components/Form';
import SubmitButton from 'components/Form/SubmitButton';
import { required } from 'components/Form/validation';

const ResetPasswordLink = styled(Link)`
  margin-left: 20px;
  font-size: 10pt;
`;

const Spacer = styled.div`
  height: 20px;
`;

const Page: React.FC = () => {
  const { push } = useHistory();
  const [showResetPasswordLink, setShowResetPasswordLink] = useState(false);
  const [, setToken] = useTokenState();
  const [serverError, setServerError] = useState<string>();

  const submit = (values: FormValues) => {
    api
      .login(values['email'], values['password'])
      .then(dto => {
        setServerError(undefined);
        window.localStorage.setItem('token', dto.token);
        setToken(dto.token);
        renewToken();
        if (dto.user.active === true) {
          push('/member/home');
        } else {
          push('/member/account');
        }
      })
      .catch((resp: Response) => {
        if (resp.status === 401) {
          setShowResetPasswordLink(true);
          setServerError('Incorrect email or password.');
        } else {
          setServerError('Internal server error');
        }
      });
  };

  return (
    <Layout>
      <Section title="Login">
        <Form onSubmit={submit} validationRules={[required(['email', 'password'])]} errorMessage={serverError}>
          <Input type="text" name="email" label="Email address" placeholder="Email address" required={true} />
          <Input type="password" name="password" label="Password" placeholder="Password" required={true} />
          <Spacer />
          <SubmitButton label="Login" />
          {showResetPasswordLink && <ResetPasswordLink url="/reset">Reset password</ResetPasswordLink>}
        </Form>
      </Section>
    </Layout>
  );
};

export default Page;
