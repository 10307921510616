import React from 'react';
import Layout from './layout';
import WishList from '../components/wishlistcontent';

const Page: React.FC = () => {
  return (
    <Layout>
      <WishList />
    </Layout>
  );
};

export default Page;
