import React, { useContext } from 'react';
import { ToastType } from './Toast';

type SetterType = (text: string, type?: ToastType) => void;

const ToastContext = React.createContext<SetterType>({} as never);

const Provider = ToastContext.Provider;

const useToast = (): ((text: string, type?: ToastType) => void) => useContext(ToastContext);

export { Provider, useToast };
