import React from 'react';
import { RouteComponentProps } from 'react-router';
import GalleryContent from '../components/gallerycontent';
import RestrictedContent from '../components/restrictedcontent';

interface RouteParams {
  galleryId: string;
  imageId?: string;
}

const Page: React.FC<RouteComponentProps<RouteParams>> = props => {
  return (
    <RestrictedContent>
      <GalleryContent {...props.match.params} />
    </RestrictedContent>
  );
};

Page.displayName = 'GalleryPage';

export default Page;
