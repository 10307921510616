import React from 'react';
import styled from 'styled-components';
import { Footer } from '../components';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  z-index: -1000;
  background-image: linear-gradient(#6f6f70, #232323);
`;

const Header = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  height: 90px;
`;

const HeaderSpacer = styled.div`
  height: 90px;
`;

const EmptyItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ContentItem = styled.div`
  width: 1000px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding-bottom: 130px;
`;

interface Props {
  header: () => React.ReactNode;
}

const BaseLayout: React.FC<Props> = props => {
  return (
    <PageContainer>
      <ContentWrapper>
        <Background />
        <Header>
          <EmptyItem />
          <ContentItem>{props.header()}</ContentItem>
          <EmptyItem />
        </Header>
        <HeaderSpacer />
        <Content>
          <EmptyItem />
          <ContentItem>{props.children}</ContentItem>
          <EmptyItem />
        </Content>
      </ContentWrapper>
      <Footer />
    </PageContainer>
  );
};

export default BaseLayout;
