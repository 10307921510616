import React, { useState, useEffect } from 'react';
import Layout from './layout';
import { Gallery, Section, Spinner } from '../../components';
import { ImageData } from '../../model';
import { GalleryInfoDto } from '../../dtos';
import { api } from '../../services';
import moment from 'moment';

const galleryInfoToImage = (g: GalleryInfoDto, index: number): ImageData => ({
  index,
  thumbnail: g.thumbnail,
  type: g.type,
  link: '/signup',
  title: g.title,
  description: moment(g.activeFrom).format('DD.MM.YYYY'),
});

const Preview: React.FC = () => {
  const [galleries, setGalleries] = useState([] as GalleryInfoDto[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .listGalleries()
      .then(dto => setGalleries(dto.galleries))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Layout>
      <Section title="Preview">
        {isLoading ? <Spinner /> : <Gallery images={galleries.map(galleryInfoToImage)} showType={true} />}
      </Section>
    </Layout>
  );
};

export default Preview;
