import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  value: string;
  onClick?: () => void;
}

const Component: React.FC<Props> = ({ className, value, onClick }) => {
  return (
    <button
      className={className}
      value={value}
      onClick={e => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
    >
      {value}
    </button>
  );
};

interface NavButtonProps {
  isFirst: boolean;
  isLast: boolean;
  selected: boolean;
}

const NavButton = styled(Component)<NavButtonProps>`
  font-family: 'Roboto', sans-serif;
  background-image: ${({ selected }) =>
    selected ? 'linear-gradient(#600404, #200000)' : 'linear-gradient(#555555, #2a2a2a)'};
  color: #efefef;
  cursor: pointer;
  outline: none;
  border: 0;
  border-top-left-radius: ${props => (props.isFirst ? '10px' : '0')};
  border-bottom-left-radius: ${props => (props.isFirst ? '10px' : '0')};
  border-top-right-radius: ${props => (props.isLast ? '10px' : '0')};
  border-bottom-right-radius: ${props => (props.isLast ? '10px' : '0')};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  &:hover {
    background-image: linear-gradient(#8b0606, #4a0000);
  }
  &:active {
    outline: none;
  }
`;

const SubmitButton = styled(Component)`
  font-family: 'Roboto', sans-serif;
  min-width: 120px;
  color: #404040;
  background-color: #cccccc;
  border: 2px solid #808080;
  margin-top: 10px;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #808080;
    color: #efefef;
  }
`;

const ActionButton = styled(Component)`
  font-family: 'Roboto', sans-serif;
  border: 0;
  border-radius: 16px;
  color: #ababab;
  background-image: linear-gradient(#555555, #2a2a2a);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 80px;
  outline: none;
  &:hover {
    background-image: linear-gradient(#8b0606, #4a0000);
  }
  &:active {
    outline: none;
  }
`;

export { ActionButton, NavButton, SubmitButton };
