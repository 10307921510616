import React from 'react';

interface Props {
  mimetype: string;
  data: string;
  className?: string;
  width?: string | number;
  height?: string | number;
}

const ImageIcon: React.FC<Props> = props => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img
    src={`data:${props.mimetype};base64,${props.data}`}
    width={props.width}
    height={props.height}
    className={props.className}
  >
    {props.children}
  </img>
);

export default ImageIcon;
