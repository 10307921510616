import React from 'react';
import { useUser } from 'globalState';
import { useHistory } from 'react-router';
import Layout from './layout';
import AccountContent from 'pages/components/accountcontent';
import NoSubscription from 'pages/components/nosubscription';
import NotConfirmed from 'pages/components/notconfirmed';
import { isEmpty } from 'util/objects';

const Page: React.FC = () => {
  const user = useUser();
  const { push } = useHistory();
  if (!user) {
    push('/signup');
    return null;
  }
  return (
    <Layout>
      {!user.active ? <NotConfirmed /> : isEmpty(user.subscriptionType) ? <NoSubscription /> : <AccountContent />}
    </Layout>
  );
};

export default Page;
