import { useText } from 'globalState';
import React from 'react';
import styled from 'styled-components';
import { Section, DbGallery } from '../../components';

const MainListLinkContainer = styled.div`
  text-align: center;
`;

const MainListLink = styled.a`
  font-family: 'Lora', Serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  color: #8b0606;
  background-image: linear-gradient(#efefef, #ababab);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 200px;
  outline: none;
  text-decoration: none;
  display: inline-block;
  &:hover {
    background-image: linear-gradient(#d0d0d0, #a8a8a8);
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const CenteredTitle = styled.h1`
  font-family: 'Lora', Serif;
  font-weight: bold;
  color: #cdcdcd;
  text-align: center;
`;

const CenteredParagraph = styled.p`
  font-family: 'Lora', Serif;
  color: #cdcdcd;
  text-align: center;
`;

const Spacer = styled.div`
  height: 20px;
`;

const WishList: React.FC = () => {
  const text = useText();
  return (
    <>
      <CenteredTitle>{text('wishlist.title')}</CenteredTitle>
      <CenteredParagraph>{text('wishlist.info')}</CenteredParagraph>
      <MainListLinkContainer>
        <MainListLink href="http://a.co/7YNupyJ" target="_blank">
          {text('wishlist.link')}
        </MainListLink>
      </MainListLinkContainer>
      <CenteredParagraph>{text('wishlist.p1')}</CenteredParagraph>
      <CenteredParagraph>{text('wishlist.p2')}</CenteredParagraph>
      <CenteredParagraph>{text('wishlist.p3')}</CenteredParagraph>
      <Spacer />
      <Section title={text('wishlist.amazon.gallery.title')}>
        <DbGallery name="AmazonGifts" />
      </Section>
      <Spacer />
      <Section title={text('wishlist.personal.gallery.title')}>
        <DbGallery name="PersonalGifts" />
      </Section>
    </>
  );
};

WishList.displayName = 'WishListContent';

export default WishList;
