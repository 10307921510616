import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { VideoGalleryDto } from '../../dtos';
import { api } from '../../services';
import { Section, Comments, Spinner } from '../../components';
import { Player } from 'video-react';

interface Props {
  videoId: string;
}

const Band = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 10px;
  background-color: black;
`;

const BandItem = styled.div`
  flex-grow: 1;
  padding: 20px 10px;
`;

const ScreenShot = styled.img`
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
`;

const StyledPlayer = styled(Player)`
  margin-top: 40px;
`;

const Component: React.FC<Props> = ({ videoId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState({} as VideoGalleryDto);

  useEffect(() => {
    setIsLoading(true);
    api
      .getGallery(videoId)
      .then(dto => setGallery(dto as VideoGalleryDto))
      .finally(() => setIsLoading(false));
  }, [videoId]);

  return (
    <div>
      {isLoading && <Spinner />}
      <Section title={gallery.title || ''}>
        {gallery.description || ''}
        <StyledPlayer playsInline={true} poster={gallery.coverImage} src={gallery.video} />
        <Band>
          {gallery.screenShots &&
            gallery.screenShots.map((img, i) => (
              <BandItem key={i}>
                <ScreenShot src={img} />
              </BandItem>
            ))}
        </Band>
      </Section>
      <Comments galleryId={videoId} />
    </div>
  );
};

Component.displayName = 'VideoContent';

export default Component;
