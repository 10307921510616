import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-family: 'Lora', Serif;
  font-weight: bold;
  color: #cdcdcd;
`;

const Paragraph = styled.span`
  font-family: 'Lora', Serif;
  font-weight: 400;
  color: #efefef;
`;

interface Props {
  title: string;
}

const Section: React.FC<Props> = props => {
  return (
    <StyledSection>
      <Title>{props.title}</Title>
      <Paragraph>{props.children}</Paragraph>
    </StyledSection>
  );
};

export default Section;
