import React, { useState } from 'react';
import styled from 'styled-components';
import { Image } from 'grommet';
import LoadingSkeleton from './LoadingSkeleton';

interface Props {
  src: string;
  onlySkeleton: boolean;
}

const StyledImage = styled(Image)<{ visible: boolean }>`
  display: block;
  ${({ visible }) => (visible ? '' : 'opacity: 0;')}
  transition: opacity .2s ease-in;
`;

const TnImage: React.FC<Props> = ({ src, onlySkeleton }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      {!onlySkeleton && (
        <StyledImage
          visible={visible}
          src={src}
          width={220}
          height={146}
          fit="contain"
          onLoad={() => setVisible(true)}
        />
      )}
      {(onlySkeleton || !visible) && <LoadingSkeleton loading={!onlySkeleton} />}
    </>
  );
};

TnImage.displayName = 'ThumbnailImage';

export default TnImage;
