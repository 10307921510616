import {
  ListGalleriesResponseDto,
  GalleryDto,
  LoginRequestDto,
  LoginResponseDto,
  RegisterRequestDto,
  CountryDto,
  ActivateRequestDto,
  UserDto,
  ResetEmailRequestDto,
  ResetPasswordRequestDto,
  RenewTokenResponseDto,
  MessageDto,
  GalleryType,
  CreateMessageRequestDto,
  UpdateUserNicknameRequestDto,
  CmsTextDto,
  CmsGiftGalleryDto,
} from '../dtos';

enum HttpStatus {
  CREATED = 201,
  NO_CONTENT = 204,
}

const handleResponse = async <T, X>(response: Response, transform?: (from: X) => T): Promise<T> => {
  if (response.status === HttpStatus.CREATED || response.status === HttpStatus.NO_CONTENT) {
    return undefined as never;
  }
  if (response.status >= 200 && response.status < 300) {
    if (transform) {
      const json = await response.json();
      return transform(json);
    }
    return response.json();
  }
  throw response;
};

const options: () => RequestInit = () => {
  const headers: Record<string, string> = {};
  const token = window.localStorage.getItem('token');
  if (token !== undefined) {
    headers.Authorization = 'Bearer ' + token;
  }
  return {
    credentials: 'omit',
    headers,
  };
};

class ApiError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

const post = async <T>(url: string, dto: unknown): Promise<T> => {
  const headers: Record<string, string> = {
    'content-type': 'application/json',
  };
  const token = window.localStorage.getItem('token');
  if (token !== undefined) {
    headers.Authorization = 'Bearer ' + token;
  }
  try {
    const response = await fetch(url, {
      body: JSON.stringify(dto),
      cache: 'no-cache',
      credentials: 'omit',
      headers,
      method: 'POST',
      mode: 'cors',
      redirect: 'follow',
      referrer: 'no-referrer',
    });
    return await handleResponse(response);
  } catch (e) {
    const response = e as Response;
    const body = await response.text();
    throw new ApiError(response.status, body ?? response.statusText);
  }
};

export const register = async (
  email: string,
  nickname: string,
  password: string,
  country: string,
  captcha: string
): Promise<void> => {
  const dto: RegisterRequestDto = {
    email,
    nickname,
    password,
    country,
    captcha,
  };
  return await post('/api/auth/register', dto);
};

export const activate: (email: string, token: string) => Promise<unknown> = (email, token) => {
  const dto: ActivateRequestDto = {
    email,
    token,
  };
  return post('/api/auth/activate', dto);
};

export const resendActivationEmail = async (): Promise<unknown> => {
  return await post('/api/auth/resendactivation', {});
};

export const sendResetEmail: (email: string, captcha: string) => Promise<unknown> = (email, captcha) => {
  const dto: ResetEmailRequestDto = {
    email,
    captcha,
  };
  return post('/api/auth/sendresetemail', dto);
};

export const resetPassword: (email: string, password: string, token: string) => Promise<void> = (
  email,
  password,
  token
) => {
  const dto: ResetPasswordRequestDto = {
    email,
    password,
    token,
  };
  return post('/api/auth/resetpassword', dto);
};

export const login = (email: string, password: string): Promise<LoginResponseDto> => {
  const dto: LoginRequestDto = {
    email,
    password,
  };
  return post('/api/auth/login', dto);
};

export const renew: () => Promise<RenewTokenResponseDto> = () => post('/api/auth/renew', {});

export const listGalleries: (type?: GalleryType) => Promise<ListGalleriesResponseDto> = async type => {
  const response = await fetch(`/api/galleries${type !== undefined ? '?type=' + type : ''}`, options());
  return handleResponse(response);
};

export const getGallery: (id: string) => Promise<GalleryDto> = async id => {
  const response = await fetch('/api/galleries/' + id, options());
  return handleResponse(response);
};

export const getLatestGalleries: () => Promise<ListGalleriesResponseDto> = async () => {
  const response = await fetch('/api/galleries/latest', options());
  return handleResponse(response);
};

export const getUpcomingGalleries: () => Promise<ListGalleriesResponseDto> = async () => {
  const response = await fetch('/api/galleries/upcoming', options());
  return handleResponse(response);
};

export const getCountries = async (): Promise<CountryDto[]> => {
  const response = await fetch('/api/countries');
  return handleResponse(response);
};

export const getContent = async (): Promise<Record<string, string>> => {
  const response = await fetch('/api/content');
  return handleResponse(response);
};

export const getText = async (): Promise<CmsTextDto[]> => {
  const response = await fetch('/api/cms/texts');
  return handleResponse(response);
};

export const getUser: () => Promise<UserDto> = async () => {
  const response = await fetch('/api/user', options());
  return handleResponse(response);
};

export const getGalleryComments: (galleryId: string) => Promise<MessageDto[]> = async galleryId => {
  const response = await fetch('/api/messages?resourceType=GALLERY&resourceId=' + galleryId, options());
  return handleResponse(response);
};

export const submitGalleryComment: (galleryId: string, parentCommentId: number, comment: string) => Promise<void> = (
  galleryId,
  parentCommentId,
  comment
) => {
  const dto: CreateMessageRequestDto = {
    resourceType: 'GALLERY',
    resourceId: galleryId,
    parentMessageId: parentCommentId,
    content: comment,
  };
  return post('/api/messages', dto);
};

export const getGiftGallery = async (name: string): Promise<CmsGiftGalleryDto | undefined> => {
  const result = await fetch(`/api/cms/gift-galleries?name=${name}`);
  return handleResponse(result, (galleries: CmsGiftGalleryDto[]) =>
    galleries.length === 1 ? galleries[0] : undefined
  );
};

export const updateUserNickname: (nickname: string) => Promise<void> = nickname => {
  const dto: UpdateUserNicknameRequestDto = {
    nickname,
  };
  return post('/api/user/nickname', dto);
};
