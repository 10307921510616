import React, { useState } from 'react';
import styled from 'styled-components';
import Label from 'components/Form/Label';
import { Section, Row as UnstyledRow, Value, InplaceEdit, DateField, SubmitButton, Text } from 'components';
import { useUser } from 'globalState';

const Row = styled(UnstyledRow)`
  margin-bottom: 10px;
`;

const AccountContent: React.FC = () => {
  const user = useUser();
  const [isPosting, setIsPosting] = useState(false);

  const updateNickname = (value: string) => {
    setIsPosting(true);
    /* TODO replace redux api
      .updateUserNickname(value)
      .then(() => dispatch(fetchUser(true)))
      .catch(err => {
        if (err.status === 400) {
          dispatch(errorToast('Could not update nickname. Please enter a valid nickname.'));
        } else {
          dispatch(errorToast('Could not update nickname: Internal server error'));
        }
      })
      .finally(() => setIsPosting(false));*/
  };
  if (!user) {
    return null;
  }
  return (
    <Section title="Account">
      <Row>
        <Label>Email:</Label>
        <Value>{user.email}</Value>
      </Row>
      <Row>
        <Label>Nickname:</Label>
        <Value>
          {!isPosting && <InplaceEdit value={user.nickname} allowEmpty={false} onChange={updateNickname} />}
        </Value>
      </Row>
      {user.subscriptionType === 'RECURRING' ? (
        <Row>
          <Text>
            Your subscription will be renewed on <DateField value={user.expirationDate} />.
          </Text>
          <SubmitButton value="Cancel Subscription" />
        </Row>
      ) : (
        <Row>
          <Text>
            Your subscription will expire on <DateField value={user.expirationDate} />.
          </Text>
        </Row>
      )}
    </Section>
  );
};

AccountContent.displayName = 'AccountContent';

export default AccountContent;
