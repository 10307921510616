import React, { useState, useEffect, useMemo } from 'react';
import { Gallery, Section, Comments, Spinner } from '../../components';
import { ImageData } from '../../model';
import { PhotoGalleryDto } from '../../dtos';
import { api } from '../../services';
import { useHistory } from 'react-router';
import Lightbox from 'components/Lightbox';

interface Props {
  galleryId: string;
  imageId?: string;
}

const Component: React.FC<Props> = ({ galleryId, imageId }) => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState({} as PhotoGalleryDto);

  useEffect(() => {
    setIsLoading(true);
    api
      .getGallery(galleryId)
      .then(dto => setGallery(dto as PhotoGalleryDto))
      .finally(() => setIsLoading(false));
  }, [galleryId]);

  const goToImage = (image: ImageData) => {
    push(`/member/photos/${gallery.id}/${image.index + 1}`);
  };

  const images: ImageData[] = useMemo(() => {
    if (gallery.images) {
      return gallery.images.map((img, index) => ({
        index,
        type: 'PHOTO',
        thumbnail: img.thumbnail,
        link: img.image,
      }));
    }
    return [];
  }, [gallery]);

  return (
    <>
      {isLoading && <Spinner />}
      <Section title={gallery.title || ''}>
        {gallery.description || ''}
        <Gallery images={images} showType={false} onClick={goToImage} />
        <Lightbox
          images={images.map(img => ({
            url: img.link || '',
            description: img.description,
          }))}
          index={Number(imageId) - 1}
          showInfo={true}
          open={imageId !== undefined}
          onClose={() => push(`/member/photos/${galleryId}`)}
          onIndexChange={index => push(`/member/photos/${gallery.id}/${index + 1}`)}
        />
      </Section>
      <Comments galleryId={galleryId} />
    </>
  );
};

Component.displayName = 'GalleryContent';

export default Component;
