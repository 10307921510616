import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 26px;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  font-size: 10pt;
  > input:checked + span:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #777777;
    width: 9px;
    height: 9px;
    content: '';
  }
`;

const Input = styled.input`
  outline: none;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
`;

const Indicator = styled.span`
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #999999;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0px;
  top: 0px;
`;

interface Props {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
}

const RadioButton: React.FC<Props> = ({ name, value, label, checked }) => (
  <Container>
    <Input type="radio" name={name} value={value} checked={checked} />
    <Indicator />
    {label}
  </Container>
);

export default RadioButton;
