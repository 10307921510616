import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

interface Props {
  children?: React.ReactNode;
  className?: string;
  url?: string;
  onClick?: () => void;
}

const StyledLink = styled.a`
  color: #dddddd;
  text-decoration: none;
`;

const Link: React.FC<Props> = ({ children, className, url, onClick }) => {
  const { push } = useHistory();
  if (url === undefined) {
    return <span className={className}>{children}</span>;
  }
  const goTo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
    push(url as string);
  };
  return (
    <StyledLink className={className} onClick={goTo} href={url}>
      {children}
    </StyledLink>
  );
};

export default Link;
