import React from 'react';
import styled from 'styled-components';
import { useText } from 'globalState';

const Container = styled.div`
  font-family: 'Roboto', Sans-Serif;
  font-size: 9pt;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 130px;
  background: #989898;
  color: #333333;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  line-height: 16px;
`;

const Footer: React.FC = () => {
  const text = useText();
  return (
    <Container>
      <p>{text('footer.copyright')}</p>
      <p>
        {text('footer.warning.1')}
        <br />
        {text('footer.warning.2')}
      </p>
      <p>{text('footer.disclaimer')}</p>
    </Container>
  );
};

export default Footer;
