import React from 'react';
import { MessageDto } from '../../dtos';
import styled from 'styled-components';
import { Identicon } from '../../components';
import moment from 'moment';
import CommentInput from './CommentInput';

interface Props {
  data: MessageDto;
  showCommentInput: boolean;
  onShowCommentInputChanged: (show: boolean) => void;
  onMessageCreate: (parentMessageId: number, content: string) => void;
}

interface ContainerProps {
  level: number;
}

const Container = styled.div<ContainerProps>`
  margin-left: ${props => props.level * 40}px;
  width: 400px;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

const AvatarContainer = styled.div`
  flex-grow: 0;
  padding-right: 10px;
`;

const MessageContainer = styled.div`
  flex-grow: 1;
`;

const MessageHeader = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
`;

const MessageAuthor = styled.span`
  font-weight: bold;
`;

const MessageBody = styled.div`
  font-size: 14px;
`;

const MessageParagraph = styled.p`
  margin-block-start: 4px;
  margin-block-end: 4px;
`;

const ReplyButton = styled.button`
  border: 0;
  color: #8b0606;
  background: none;
  cursor: pointer;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const Comment: React.FC<Props> = props => {
  const replyClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onShowCommentInputChanged(true);
  };

  const cancelInput = () => {
    props.onShowCommentInputChanged(false);
  };

  const sendInput = (messageId: number, input: string) => {
    if (input === undefined || input.trim().length === 0) {
      return;
    }
    props.onMessageCreate(messageId, input);
    props.onShowCommentInputChanged(false);
  };

  return (
    <Container level={props.data.level}>
      <AvatarContainer>
        <Identicon hash={props.data.emailHash} />
      </AvatarContainer>
      <MessageContainer>
        <MessageHeader>
          <MessageAuthor>{props.data.nickname}</MessageAuthor> on{' '}
          {moment(props.data.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
        </MessageHeader>
        <MessageBody>
          {props.data.content.split('\n').map((p, i) => (
            <MessageParagraph key={i}>{p}</MessageParagraph>
          ))}
        </MessageBody>
        {props.showCommentInput ? (
          <CommentInput onCancel={cancelInput} onSend={sendInput} parentId={props.data.id} />
        ) : (
          <ReplyButton onClick={replyClicked}>Reply</ReplyButton>
        )}
      </MessageContainer>
    </Container>
  );
};

export default Comment;
