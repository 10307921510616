import React from 'react';
import styled from 'styled-components';

interface Props {
  errorMessage?: string;
}

const Container = styled.div`
  background: #ffcfcf;
  font-family: Roboto;
  font-size: 18px;
  color: #cc0000;
  padding: 12px 22px;
  margin: -10px -10px 16px -10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const FormError: React.FC<Props> = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }
  return <Container>{errorMessage}</Container>;
};

FormError.displayName = 'FormError';

export default FormError;
