import React from 'react';
import styled from 'styled-components';

interface Props {
  index: number;
  total: number;
}

const Container = styled.div`
  position: absolute;
  font-family: Lora;
  font-size: 14px;
  padding: 8px;
  color: #f8f8f8;
`;

const Info: React.FC<Props> = ({ index, total }) => {
  return (
    <Container>
      {index} / {total}
    </Container>
  );
};

Info.displayName = 'LightboxInfo';

export default Info;
