import Icon from 'components/Icon';
import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  padding: 10px;
`;

const Crumb = styled.span`
  font-family: Arial, Sans-serif;
  font-size: 11pt;
  color: #666666;
`;

const CrumbLink = styled(Link)`
  text-decoration: none;
`;

const Separator = styled(Icon)`
  margin-left: 6px;
  margin-right: 6px;
  color: #c8c8c8;
`;

export interface CrumbData {
  label: string;
  url?: string;
}

interface Props {
  crumbs: CrumbData[];
}

const Breadcrumbs: React.FC<Props> = props => {
  const { crumbs } = props;
  if (crumbs.length === 0) {
    return null;
  }
  const crumbElems: React.ReactNode[] = [];
  for (let i = 0; i < crumbs.length - 1; i++) {
    const c =
      crumbs[i].url === undefined ? (
        <Crumb>{crumbs[i].label}</Crumb>
      ) : (
        <CrumbLink url={crumbs[i].url}>
          <Crumb>{crumbs[i].label}</Crumb>
        </CrumbLink>
      );
    crumbElems.push(
      <span key={i}>
        {c}
        <Separator type="chevron-right" />
      </span>
    );
  }
  crumbElems.push(<Crumb key={crumbs.length - 1}>{crumbs[crumbs.length - 1].label}</Crumb>);
  return <Container>{crumbElems}</Container>;
};

export default Breadcrumbs;
