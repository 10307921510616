import Icon from 'components/Icon';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
  value: string;
  allowEmpty?: boolean;
  onChange: (value: string) => void;
}

const Input = styled.input`
  border 1px solid #666666;
  border-radius: 3px;
  font-family: "Lora", sans-serif;
`;

const IconButton = styled(Icon)`
  cursor: pointer;
  margin-left: 10px;
`;

const InplaceEdit: React.FC<Props> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  });

  const confirm = () => {
    setIsEditing(false);
    props.onChange(value);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      e.preventDefault();
      confirm();
    }
  };

  const onStartEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const onConfirm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    confirm();
  };

  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsEditing(false);
    setValue(props.value);
  };

  return isEditing ? (
    <span>
      <Input ref={inputRef} type="text" value={value} onChange={handleValueChange} onKeyPress={onKeyPress} />
      <span onClick={onConfirm}>
        <IconButton type="check" />
      </span>
      <span onClick={onCancel}>
        <IconButton type="x-circle" />
      </span>
    </span>
  ) : (
    <span onClick={onStartEdit}>
      {props.value}{' '}
      <span>
        <IconButton type="edit" />
      </span>
    </span>
  );
};

export default InplaceEdit;
