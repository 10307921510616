import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Label = styled.div`
  width: 200px;
  font-family: 'Lora', Serif;
  font-size: 10pt;
  color: #efefef;
`;

export const Value = styled.div`
  width: 400px;
  font-family: 'Lora', Serif;
  font-size: 10pt;
  color: #efefef;
  font-weight: 600;
`;
