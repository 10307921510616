import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  hasText: boolean;
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.2s ease;
  background-color: rgba(255, 255, 255, 0);
  &:hover {
    background-color: rgba(255, 255, 255, ${props => (props.hasText ? '0.8' : '0.2')});
    opacity: 1;
  }
`;

const Title = styled.h1`
  margin-top: 30px;
  text-align: center;
  color: #850200;
  font-size: 18px;
  font-weight: 600;
`;

const Description = styled.p`
  text-align: center;
  color: #850200;
`;

interface Props {
  title?: string;
  description?: string;
  onMouseOver?(): void;
  onMouseOut?(): void;
}

const Overlay: React.FC<Props> = props => {
  if (!props.title && !props.description) {
    return <Container hasText={false} />;
  }
  return (
    <Container hasText={true} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </Container>
  );
};

export default Overlay;
