import React from 'react';
import RestrictedContent from '../components/restrictedcontent';
import VideoContent from '../components/videocontent';
import { RouteComponentProps } from 'react-router';

interface RouteParams {
  videoId: string;
}

const Page: React.FC<RouteComponentProps<RouteParams>> = props => {
  return (
    <RestrictedContent>
      <VideoContent videoId={props.match.params.videoId} />
    </RestrictedContent>
  );
};

export default Page;
