import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.div`
  font-family: 'Roboto', Sans-Serif;
  font-size: 9pt;
  line-height: 28px;
  color: #efefef;
`;

const Label: React.FC = ({ children }) => {
  return <StyledLabel>{children}</StyledLabel>;
};

export default Label;
