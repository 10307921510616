export const isEmpty: (o: unknown) => boolean = o => {
  if (o === undefined) {
    return true;
  }
  if (typeof o === 'object') {
    return Object.keys(o as Record<string, unknown>).length === 0;
  }
  return o === '';
};

const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const isEmail: (s?: string) => boolean = s => (s && EMAIL_PATTERN.test(s)) || false;
