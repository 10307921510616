import React, { useState, useEffect } from 'react';
import { Gallery, Section, Spinner } from '../../components';
import { ImageData } from '../../model';
import { GalleryInfoDto, GalleryType } from '../../dtos';
import { api } from '../../services';
import moment from 'moment';

interface Props {
  galleryType: GalleryType;
}

const galleryInfoToImage: (g: GalleryInfoDto, index: number) => ImageData = (g, index) => ({
  index,
  thumbnail: g.thumbnail,
  type: g.type,
  link: `/member/${g.type === 'PHOTO' ? 'photos' : 'videos'}/${g.id}`,
  title: g.title,
  description: moment(g.activeFrom).format('DD.MM.YYYY'),
});

const Component: React.FC<Props> = props => {
  const [galleries, setGalleries] = useState([] as GalleryInfoDto[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .listGalleries(props.galleryType)
      .then(dto => setGalleries(dto.galleries))
      .finally(() => setIsLoading(false));
  }, [props.galleryType]);

  return (
    <Section title={props.galleryType === 'PHOTO' ? 'Photos' : 'Videos'}>
      {isLoading ? <Spinner /> : <Gallery images={galleries.map(galleryInfoToImage)} showType={false} />}
    </Section>
  );
};

Component.displayName = 'GalleriesContent';

export default Component;
