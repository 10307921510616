import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from './layout';
import { Section, Form } from '../../components';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendResetEmail } from 'services/api';
//import * as qs from 'query-string';
import { useLocation } from 'react-router';
import { useContent } from 'globalState';
import { FormValues } from 'components/Form';
import SubmitButton from 'components/Form/SubmitButton';

const SuccessInfo = styled.div`
  padding: 8px;
  color: #999999;
  margin-top: 10px;
  background-color: #cdcdcd;
  border-radius: 3px;
  border: 1px solid #999999;
`;

const Page: React.FC = () => {
  const { search } = useLocation();
  const content = useContent();
  const [captcha, setCaptcha] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    //const e: string = qs.parse(search).u as string;
    //const t: string = qs.parse(search).t as string;
    // TODO
  }, [search]);

  /*
  const validate = () => {
    if (isEmpty(email.value as string)) {
      setErrorMessage('Email cannot be empty.');
      return false;
    }
    if (!isEmail(email.value as string)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    setErrorMessage('');
    return true;
  };*/

  const submit = (values: FormValues) => {
    sendResetEmail(values['email'], captcha).then(() => {
      //dispatch(successToast('Email with reset link has been sent.'));
      setSuccess(true);
    });
    //.catch(() => dispatch(errorToast('Internal server error')));
  };

  const captchaChanged = (value: string) => {
    setCaptcha(value);
  };
  const clearCaptcha = () => {
    setCaptcha('');
  };
  return (
    <Layout>
      <Section title="Reset Password">
        {success ? (
          <SuccessInfo>Please follow the instructions in the email to reset your password.</SuccessInfo>
        ) : (
          <Form onSubmit={submit}>
            {/*<Input type="text" name="email" label="Email address" placeholder="Email address" {...email} />*/}
            <ReCAPTCHA
              sitekey={content('captcha.sitekey')}
              onChange={captchaChanged}
              onErrored={clearCaptcha}
              onExpired={clearCaptcha}
            />
            <SubmitButton label="Send reset email" />
          </Form>
        )}
      </Section>
    </Layout>
  );
};

export default Page;
