import { FormErrors, ValidationRule } from '.';

export const required = (names: string[]): ValidationRule => values => {
  const errors: FormErrors = {};
  names.forEach(name => {
    if (!values[name]) {
      errors[name] = ['This field is required'];
    }
  });
  return errors;
};

export const equal = (names: string[], message: string): ValidationRule => values => {
  const errors: FormErrors = {};
  if (names.length > 1) {
    const value = values[names[0]];
    if (value) {
      for (let i = 0; i < names.length; i++) {
        if (value !== values[names[i]]) {
          names.forEach(name => {
            errors[name] = [message];
          });
          break;
        }
      }
    }
  }
  return errors;
};

export const length = (name: string, lengthConstraints: { min?: number; max?: number }): ValidationRule => values => {
  const errors: FormErrors = {};
  const value = values[name];
  if (lengthConstraints.min && value?.length < lengthConstraints.min) {
    errors[name] = [`The minimum lengh for this field is ${lengthConstraints.min} characters`];
  } else if (lengthConstraints.max && value?.length > lengthConstraints.max) {
    errors[name] = [`The maximum lengh for this field is ${lengthConstraints.max} characters`];
  }
  return errors;
};
