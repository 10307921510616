import React from 'react';
import styled from 'styled-components';
import { Button } from 'grommet';
import Icon from 'components/Icon';

interface Props {
  onPrev: () => void;
  onNext: () => void;
  onClose: () => void;
  isFullScreen: boolean;
  onFullScreen: (enabled: boolean) => void;
}

const IconButton = styled(Button)`
  color: #f8f8f8;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    svg {
      stroke-width: 2.4;
      stroke: #ffffff;
    }
  }
`;

const NavButton = styled(IconButton)<{ side: 'left' | 'right' }>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  ${({ side }) => side}: 24px;
  top: 50%;
  background: rgba(255, 255, 255, 0.15);
`;

const CloseButton = styled(IconButton)`
  top: 8px;
  right: 32px;
  width: 40px;
  height: 40px;
`;

const FullScreenButton = styled(IconButton)`
  top: 8px;
  right: 80px;
  width: 40px;
  height: 40px;
`;

const Controls: React.FC<Props> = ({ onPrev, onNext, onClose, isFullScreen, onFullScreen }) => {
  return (
    <>
      <NavButton side="left" onClick={onPrev}>
        <Icon type="chevron-left" size="large" />
      </NavButton>
      <NavButton side="right" onClick={onNext}>
        <Icon type="chevron-right" size="large" />
      </NavButton>
      <CloseButton onClick={onClose}>
        <Icon type="x" size="medium" />
      </CloseButton>
      <FullScreenButton onClick={() => onFullScreen(!isFullScreen)}>
        <Icon type={isFullScreen ? 'minimize' : 'maximize'} size="medium" />
      </FullScreenButton>
    </>
  );
};

Controls.displayName = 'LightboxControls';

export default Controls;
