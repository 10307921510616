import React from 'react';
import moment from 'moment';

interface Props {
  value: Date;
}

export const DateField: React.FC<Props> = props => {
  return <span>{(props.value && moment(props.value).format('DD/MM/YYYY')) || ''}</span>;
};

export const DateTimeField: React.FC<Props> = props => {
  return <span>{(props.value && moment(props.value).format('DD/MM/YYYY HH:mm:ss')) || ''}</span>;
};
