import Icon from 'components/Icon';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.2s ease;
  color: rgba(255, 255, 255, 0.3);
`;

interface Props {
  icon: 'image' | 'video';
}

const Overlay: React.FC<Props> = ({ icon }) => {
  return (
    <Container>
      <Icon type={icon} size="large" />
    </Container>
  );
};

export default Overlay;
