import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

const Container = styled.div`
  font-family: Lora;
  font-size: 18px;
  color: #f8f8f8;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
`;

const Caption: React.FC<Props> = ({ text }) => {
  return <Container>{text}</Container>;
};

Caption.displayName = 'LightboxCaption';

export default Caption;
