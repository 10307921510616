import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Image, Keyboard, Layer } from 'grommet';
import { noop } from 'lodash';
import { Spinner } from 'components';
import Controls from './controls';
import Caption from './caption';
import Info from './info';

interface ImageProps {
  url: string;
  description?: string;
}

interface Props {
  open: boolean;
  images: ImageProps[];
  index: number;
  onIndexChange?: (index: number) => void;
  onClose?: () => void;
  showInfo?: boolean;
}

const StyledImage = styled(Image)<{ visible: boolean }>`
  ${({ visible }) => (visible ? '' : 'opacity: 0;')}
  transition: opacity .2s ease-in;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLayer = styled(Layer)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Lightbox: React.FC<Props> = ({ open, images, index, showInfo = false, onIndexChange = noop, onClose = noop }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => setLoading(true), [index]);
  useEffect(() => {
    if (fullScreen) {
      if (!document.fullscreenElement) {
        ref.current?.requestFullscreen();
      }
    } else if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [fullScreen]);
  useEffect(() => {
    const handler = () => setFullScreen(!!document.fullscreenElement);
    document.addEventListener('fullscreenchange', handler, false);
    return () => {
      document.removeEventListener('fullscreenchange', handler);
    };
  }, []);
  if (!open || index >= images.length) {
    return null;
  }
  const img = images[index];
  const handlePrev = () => {
    if (index > 0) {
      onIndexChange(index - 1);
    }
  };
  const handleNext = () => {
    if (index < images.length - 1) {
      onIndexChange(index + 1);
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (loading) {
      return;
    }
    switch (e.key) {
      case 'ArrowLeft':
        handlePrev();
        break;
      case 'ArrowRight':
        handleNext();
        break;
    }
  };
  return (
    <Keyboard target="document" onKeyDown={handleKeyPress}>
      <StyledLayer onEsc={onClose} modal={true} animation="fadeIn" ref={ref as never}>
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        <Overlay>
          {showInfo && <Info index={index + 1} total={images.length} />}
          <Controls
            onClose={onClose}
            onPrev={handlePrev}
            onNext={handleNext}
            isFullScreen={fullScreen}
            onFullScreen={setFullScreen}
          />
          {img.description && <Caption text={img.description} />}
        </Overlay>
        <StyledImage
          src={img.url}
          fit="contain"
          fill="horizontal"
          onLoad={() => setLoading(false)}
          visible={!loading}
        />
      </StyledLayer>
    </Keyboard>
  );
};

Lightbox.displayName = 'Lightbox';

export default Lightbox;
