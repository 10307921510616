import { useFormikContext } from 'formik';
import { Button } from 'grommet';
import React from 'react';

interface Props {
  label?: string;
}

const SubmitButton: React.FC<Props> = ({ label = 'Submit' }) => {
  const { submitForm } = useFormikContext();
  return <Button primary={true} label={label} onClick={submitForm} />;
};

SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
