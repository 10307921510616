const color: (rgba: RGBA) => string = rgba =>
  `rgba(${Math.round(rgba[0])}, ${Math.round(rgba[1])}, ${Math.round(rgba[2])}, ${
    rgba[3] >= 0 && rgba[3] <= 255 ? rgba[3] / 255 : 1
  })`;

export const createSvg: (size: number, foreground: RGBA, background: RGBA, rectangles: Rectangle[]) => string = (
  size,
  foreground,
  background,
  rectangles
) => {
  const fg = color(foreground);
  const bg = color(background);
  const cell = size / 5;
  let xml = '<?xml version="1.0" encoding="UTF-8"?>';
  xml += `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" style="background-color: ${bg};">`;
  xml += `<g style="fill: ${fg}; stroke: ${fg}; stroke-width: ${size * 0.005};">`;
  xml += rectangles.map(r => `<rect x="${r.x * cell}" y="${r.y * cell}" width="${cell}" height="${cell}" />`).join('');
  xml += '</g></svg>';
  return xml;
};
