import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
`;

const animation = keyframes`
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
`;

const Circle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #efefef;
    border-radius: 100%;
    -webkit-animation: ${animation} 1.2s infinite ease-in-out both;
    animation: ${animation} 1.2s infinite ease-in-out both;
  }
`;

interface CircleProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  angle: number;
  delay: number;
}

const Circle2 = styled(Circle)<CircleProps>`
  -webkit-transform: rotate(${props => props.angle}deg);
  -ms-transform: rotate(${props => props.angle}deg);
  transform: rotate(${props => props.angle}deg);
  &:before {
    -webkit-animation-delay: ${props => props.delay}s;
    animation-delay: ${props => props.delay}s;
  }
`;

const Spinner: React.FC = () => {
  const c: React.ReactNode[] = [];
  for (let i = 1; i < 12; i++) {
    c.push(<Circle2 key={i} angle={360 - i * 30} delay={1.2 - i * 0.1} />);
  }
  return (
    <Container>
      <Circle />
      {c}
    </Container>
  );
};

export default Spinner;
