import React from 'react';
import Label from './Label';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Box } from 'grommet';

interface Props {
  label?: string;
  name: string;
  required?: boolean;
}

const StyledError = styled.span`
  font-family: Roboto;
  font-size: 12px;
  color: #cc0000;
`;

const RequiredMark = styled.div`
  display: inline-block;
  color: #850200;
  font-size: 20px;
  vertical-align: middle;
  margin-left: 4px;
`;

const FormField: React.FC<Props> = ({ label, name, required = false, children }) => {
  const { errors, submitCount } = useFormikContext<Record<string, string>>();
  return (
    <>
      <Label>
        {label}
        {required && <RequiredMark>*</RequiredMark>}
      </Label>
      {children}
      {errors[name] && submitCount > 0 && (
        <Box>
          {((errors[name] as unknown) as string[]).map((err, i) => (
            <StyledError key={`${name}-err-${i}`}>{err}</StyledError>
          ))}
        </Box>
      )}
    </>
  );
};

FormField.displayName = 'FormField';

export default FormField;
