import React, { useState, useEffect } from 'react';
import { MessageDto } from '../../dtos';
import Comment from './Comment';
import { api } from '../../services';
import styled from 'styled-components';
import CommentInput from './CommentInput';
import { orderMessages } from './treeutil';
import { useToast } from 'components/Toast';

interface Props {
  galleryId: string;
}

const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-weight: 300;
  margin-top: 0;
`;

const AddButton = styled.button`
  border: 0;
  color: #8b0606;
  background: none;
  cursor: pointer;
  margin-top: 10px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const Comments: React.FC<Props> = props => {
  const [comments, setComments] = useState([] as MessageDto[]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showCommentInputIndex, setShowCommentInputIndex] = useState(-1);
  const toast = useToast();

  const fetchComments = () => {
    api.getGalleryComments(props.galleryId).then(setComments);
  };

  useEffect(fetchComments, [props.galleryId]);

  const addComment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowCommentInput(true);
    setShowCommentInputIndex(-1);
  };

  const cancelInput = () => {
    setShowCommentInput(false);
  };

  const sendInput = (commentId: number, input: string) => {
    if (!input || input.trim().length === 0) {
      return;
    }
    api
      .submitGalleryComment(props.galleryId, commentId, input)
      .then(() => {
        setShowCommentInput(false);
        fetchComments();
      })
      .catch(() => toast('Posting the comment failed', 'ERROR'));
  };

  const onShowCommentInputChanged = (show: boolean, index: number) => {
    setShowCommentInputIndex(show ? index : -1);
    if (show) {
      setShowCommentInput(false);
    }
  };

  return (
    <Container>
      <Title>
        {comments.length > 0 ? `${comments.length} Comment${comments.length === 1 ? '' : 's'}` : 'Comments'}
      </Title>
      {comments.length === 0 ? (
        showCommentInput ? (
          <CommentInput onCancel={cancelInput} onSend={sendInput} />
        ) : (
          <AddButton onClick={addComment}>Be the first to comment this page</AddButton>
        )
      ) : (
        <div>
          {orderMessages(comments).map((c, i) => (
            <Comment
              key={i}
              showCommentInput={showCommentInputIndex === i}
              onShowCommentInputChanged={show => onShowCommentInputChanged(show, i)}
              data={c}
              onMessageCreate={sendInput}
            />
          ))}
          {showCommentInput ? (
            <CommentInput onCancel={cancelInput} onSend={sendInput} />
          ) : (
            <AddButton onClick={addComment}>Add comment</AddButton>
          )}
        </div>
      )}
    </Container>
  );
};

export default Comments;
