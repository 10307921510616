import { ActionButton, Form, Input, Section } from 'components';
import SubmitButton from 'components/Form/SubmitButton';
import { required } from 'components/Form/validation';
import { useToast } from 'components/Toast';
import { useUser } from 'globalState';
import React from 'react';
import { useHistory } from 'react-router';
import { activate, resendActivationEmail } from 'services/api';
import styled from 'styled-components';

const Spacer = styled.div`
  height: 20px;
`;

const NotConfirmed: React.FC = () => {
  const user = useUser();
  const toast = useToast();
  const { push } = useHistory();
  if (!user) {
    push('/login');
    return null;
  }
  const handleSubmit = (values: Record<string, string>) => {
    activate(user.email, values['code'])
      .then(() => {
        toast('Activation successful!');
        // TODO: reload user
      })
      .catch(e => {
        toast('Activation failed', 'ERROR');
        console.error(e);
      });
  };
  const handleResendActivationEmail = () => {
    resendActivationEmail()
      .then(() => {
        toast('Activation email sent!');
      })
      .catch(e => {
        toast('Could not send activatoin email', 'ERROR');
        console.error(e);
      });
  };
  return (
    <>
      <Section title="Your account is not active">
        <p>Please confirm your email address by clicking on the link in the confirmation email.</p>
        <p>Alternatively you can enter the confirmation code here.</p>
        <Form onSubmit={handleSubmit} validationRules={[required(['code'])]}>
          <Input name="code" label="Code" required={true} placeholder="Code" />
          <Spacer />
          <SubmitButton label="Activate" />
        </Form>
      </Section>
      <ActionButton value="Resend activation email" onClick={handleResendActivationEmail} />
    </>
  );
};

NotConfirmed.displayName = 'NotConfirmed';

export default NotConfirmed;
