import React, { useState } from 'react';
import styled from 'styled-components';
import Link from '../Link';
import DefaultOverlay from './DefaultOverlay';
import HoverOverlay from './HoverOverlay';
import { ImageData } from '../../model';
import { noop } from 'lodash';
import TnImage from './TnImage';

const ThumbnailContainer = styled.div`
  margin: 10px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 222px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border: 1px solid #ababab;
  }
`;

const ImageLink = styled(Link)`
  box-sizing: border-box;
`;

interface Props {
  image: ImageData;
  showType: boolean;
  onlySkeleton?: boolean;
  onClick?: (image: ImageData) => void;
}

const Thumbnail: React.FC<Props> = ({ image, showType, onlySkeleton = false, onClick = noop }) => {
  const [showDefaultOverlay, setShowDefaultOverlay] = useState(true);
  const { thumbnail, title, description, link, type } = image;
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(image);
  };
  const tn: JSX.Element = (
    <ImageContainer>
      <TnImage src={thumbnail} onlySkeleton={onlySkeleton} />
      {showType && showDefaultOverlay && <DefaultOverlay icon={type === 'PHOTO' ? 'image' : 'video'} />}
      <HoverOverlay
        title={title}
        description={description}
        onMouseOver={() => setShowDefaultOverlay(false)}
        onMouseOut={() => setShowDefaultOverlay(true)}
      />
    </ImageContainer>
  );
  return (
    <ThumbnailContainer>
      <div onClick={handleClick}>{link ? <ImageLink url={link}>{tn}</ImageLink> : tn}</div>
    </ThumbnailContainer>
  );
};

export default Thumbnail;
