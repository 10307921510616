import React, { useEffect, useState } from 'react';
import ToastComponent, { ToastProps, ToastType } from './Toast';
import { Provider, useToast } from './ToastContext';

export { useToast };

const Toast: React.FC = ({ children }) => {
  const [value, setValue] = useState<ToastProps>({ text: '', type: 'SUCCESS' });
  const [open, setOpen] = useState(false);
  const setter = (text: string, type: ToastType = 'SUCCESS') => {
    setValue({ text, type });
    setOpen(true);
  };
  useEffect(() => {
    let timeout: number | undefined = undefined;
    if (open) {
      timeout = (setTimeout(() => setOpen(false), 3000) as unknown) as number;
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);
  return (
    <Provider value={setter}>
      <>
        {open && <ToastComponent {...value} />}
        {children}
      </>
    </Provider>
  );
};

Toast.displayName = 'Toast';

export default Toast;
