import React, { useState } from 'react';
import styled from 'styled-components';
import { ImageData } from 'model';
import { Thumbnail } from 'components';
import LazyLoad from 'react-lazy-load';

interface Props {
  images: ImageData[];
  showType: boolean;
  onClick?: (image: ImageData) => void;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 170px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLazyLoad = styled(LazyLoad)`
  position: absolute;
  top: 0;
`;

const LazyLoadingRow: React.FC<Props> = ({ images, showType, onClick }) => {
  const [onlySkeleton, setOnlySkeleton] = useState(true);
  return (
    <Container>
      <Row>
        {images.map((img, i) => (
          <Thumbnail key={i} image={img} showType={showType} onlySkeleton={onlySkeleton} onClick={onClick} />
        ))}
      </Row>
      <StyledLazyLoad width={1} height={170} debounce={false} onContentVisible={() => setOnlySkeleton(false)}>
        <div />
      </StyledLazyLoad>
    </Container>
  );
};

LazyLoadingRow.displayName = 'LazyLoadingRow';

export default LazyLoadingRow;
