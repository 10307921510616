import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from './layout';
import { Form, Input, Section, Country } from '../../components';
import SubmitButton from '../../components/Form/SubmitButton';
import { register } from 'services/api';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';
import { useContent } from 'globalState';
import { equal, required, length } from 'components/Form/validation';
import { FormValues } from 'components/Form';
import { useToast } from 'components/Toast';

const Spacer = styled.div`
  height: 20px;
`;

const Page: React.FC = () => {
  const { push } = useHistory();
  const content = useContent();
  const [captcha, setCaptcha] = useState('');
  const toast = useToast();
  const [serverError, setServerError] = useState<string>();

  const submit = (values: FormValues) => {
    register(values['email'], values['nickname'], values['password'], values['country'], captcha)
      .then(() => {
        toast('Account created');
        push('/login');
      })
      .catch(e => {
        setServerError(`Server error: ${e.message}`);
      });
  };

  const captchaChanged = (value: string) => {
    setCaptcha(value);
  };
  const clearCaptcha = () => {
    setCaptcha('');
  };
  return (
    <Layout>
      <Section title="Create Account">
        <Form
          onSubmit={submit}
          validationRules={[
            required(['email', 'password', 'passwordConfirmation', 'country']),
            equal(['password', 'passwordConfirmation'], 'Passwords must be equal'),
            length('password', { min: 8, max: 20 }),
            length('passwordConfirmation', { min: 8, max: 20 }),
          ]}
          errorMessage={serverError}
        >
          <Input name="email" label="Email address" placeholder="Email address" required={true} />
          <Input name="nickname" label="Nickname" placeholder="Nickname" />
          <Input type="password" name="password" label="Password" placeholder="Password" required={true} />
          <Input
            type="password"
            name="passwordConfirmation"
            label="Password confirmation"
            placeholder="Password"
            required={true}
          />
          <Country name="country" label="Country" required={true} />
          <Spacer />
          <ReCAPTCHA
            sitekey={content('captcha.sitekey')}
            onChange={captchaChanged}
            onErrored={clearCaptcha}
            onExpired={clearCaptcha}
          />
          <Spacer />
          <SubmitButton label="Create account" />
        </Form>
      </Section>
    </Layout>
  );
};

export default Page;
