import React, { useState, useEffect } from 'react';
import Layout from './layout';
import { Section, PreviewItem } from '../../components';
import { api } from '../../services';
import { GalleryInfoDto } from '../../dtos';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Carousel } from 'grommet';

const Band = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const BandItem = styled.div`
  flex-grow: 1;
`;

const Page: React.FC = () => {
  const { push } = useHistory();
  const [latestGalleries, setLatestGalleries] = useState([] as GalleryInfoDto[]);

  useEffect(() => {
    api.getLatestGalleries().then(list => setLatestGalleries(list.galleries));
  }, []);

  return (
    <Layout>
      <div>
        <Carousel>
          <img src="/assets/images/slide01.jpg" height={400} alt="" />
          <img src="/assets/images/slide02.jpg" height={400} alt="" />
        </Carousel>
        <Section title="Latest Updates">
          <Band onClick={() => push('/preview')}>
            {latestGalleries.map((g, i) => (
              <BandItem key={i}>
                <PreviewItem imageUrl={g.thumbnail} type={g.type} />
              </BandItem>
            ))}
          </Band>
        </Section>
      </div>
    </Layout>
  );
};

export default Page;
