import React from 'react';
import styled from 'styled-components';
import ImageIcon from '../ImageIcon';
import { createSvg } from './svg';
import { hsl2rgb } from './util';

interface Props {
  hash: string;
}

const create: (s: string) => Rectangle[] = s => {
  const rectangles: Rectangle[] = [];
  for (let i = 0; i < 15; i++) {
    if (parseInt(s.charAt(i), 16) % 2 === 1) {
      if (i < 5) {
        rectangles.push({ x: 2, y: i });
      } else if (i < 10) {
        rectangles.push({ x: 1, y: i - 5 });
        rectangles.push({ x: 3, y: i - 5 });
      } else {
        rectangles.push({ x: 0, y: i - 10 });
        rectangles.push({ x: 4, y: i - 10 });
      }
    }
  }
  return rectangles;
};

const StyledIcon = styled(ImageIcon)`
  border: 1px solid #444444;
  border-radius: 7px;
`;

const Identicon: React.FC<Props> = props => {
  const hue = parseInt(props.hash.substr(-7), 16) / 0xfffffff;
  const svg = createSvg(50, hsl2rgb(hue, 0.7, 0.5), [240, 240, 240, 255], create(props.hash));
  return <StyledIcon mimetype="image/svg+xml" data={btoa(svg)} />;
};

export default Identicon;
