import React from 'react';
import Layout from './layout';
import About from '../components/aboutcontent';

const Page: React.FC = () => {
  return (
    <Layout>
      <About />
    </Layout>
  );
};

export default Page;
