import React from 'react';
import styled from 'styled-components';
import Overlay from './Overlay';
import { GalleryType } from '../../dtos';

interface Props {
  imageUrl: string;
  type: GalleryType;
}

const Image = styled.img`
  display: block;
  width: 100%;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

const PreviewItem: React.FC<Props> = props => {
  return (
    <ImageContainer>
      <Image src={props.imageUrl} />
      <Overlay icon={props.type === 'PHOTO' ? 'image' : 'video'} />
    </ImageContainer>
  );
};

export default PreviewItem;
