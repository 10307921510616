import Icon from 'components/Icon';
import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  loading: boolean;
}

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 220px;
  height: 146px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Background = styled.div`
  position: absolute;
  top: 16px;
  color: rgba(255, 255, 255, 0.12);
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const barAnimation = keyframes`
  0% {
    background: rgba(0, 0, 0, 0);
    height: 24px;
  }
  27%, 33% {
    background: rgba(255, 255, 255, 0.67);
    height: 72px;
  }
  46%, 100% {
    background: rgba(0, 0, 0, 0);
    height: 48px;
  }
`;

const Bar = styled.div<{ animationDelay: string }>`
  width: 20px;
  margin: 0 4px;
  animation: ${barAnimation} 1.6s infinite ease-in-out;
  animation-delay: ${({ animationDelay }) => animationDelay};
`;

const LoadingSkeleton: React.FC<Props> = ({ loading }) => {
  return (
    <Container>
      <Background>
        <Icon type="image" size="xxlarge" />
      </Background>
      {loading && (
        <BarContainer>
          <Bar animationDelay="0.12s" />
          <Bar animationDelay="0.24s" />
          <Bar animationDelay="0.36s" />
          <Bar animationDelay="0.48s" />
        </BarContainer>
      )}
    </Container>
  );
};

LoadingSkeleton.displayName = 'LoadingSkeleton';

export default LoadingSkeleton;
