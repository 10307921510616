import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from './layout';
import { Section, PreviewItem } from '../../components';
import { GalleryInfoDto } from '../../dtos';
import { api } from '../../services';
import { useHistory } from 'react-router';

const Band = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const BandItem = styled.div`
  flex-grow: 1;
`;

const Page: React.FC = () => {
  const [latestGalleries, setLatestGalleries] = useState([] as GalleryInfoDto[]);
  const { push } = useHistory();

  useEffect(() => {
    api.getLatestGalleries().then(list => setLatestGalleries(list.galleries));
  }, []);

  const previewItemClicked = (e: React.MouseEvent<HTMLDivElement>, g: GalleryInfoDto) => {
    e.preventDefault();
    push(`/member/${g.type === 'PHOTO' ? 'photos' : 'videos'}/${g.id}`);
  };

  return (
    <Layout>
      <div>
        <Section title="Latest Updates">
          <Band>
            {latestGalleries.map((g, i) => (
              <BandItem key={i} onClick={e => previewItemClicked(e, g)}>
                <PreviewItem imageUrl={g.thumbnail} type={g.type} />
              </BandItem>
            ))}
          </Band>
        </Section>
      </div>
    </Layout>
  );
};

export default Page;
