import React from 'react';
import styled from 'styled-components';
import { Section, Value, Label, Row as UnstyledRow } from '../../components';
import { useText } from 'globalState';

const Container = styled.div`
  width: 600px;
`;

const Spacer = styled.div`
  height: 40px;
`;

const Row = styled(UnstyledRow)`
  margin-bottom: 10px;
`;

const About: React.FC = () => {
  const text = useText();
  return (
    <Container>
      <Section title={text('about.title')}>
        <p>{text('about.p1')}</p>
        <p>{text('about.p2')}</p>
        <p>{text('about.p3')}</p>
      </Section>
      <Spacer />
      <Section title={text('about.infotitle')}>
        <Row>
          <Label>{text('about.info.l1')}</Label>
          <Value>{text('about.info.v1')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l2')}</Label>
          <Value>{text('about.info.v2')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l3')}</Label>
          <Value>{text('about.info.v3')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l4')}</Label>
          <Value>{text('about.info.v4')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l5')}</Label>
          <Value>{text('about.info.v5')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l6')}</Label>
          <Value>{text('about.info.v6')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l7')}</Label>
          <Value>{text('about.info.v7')}</Value>
        </Row>
        <Row>
          <Label>{text('about.info.l8')}</Label>
          <Value>{text('about.info.v8')}</Value>
        </Row>
      </Section>
    </Container>
  );
};

About.displayName = 'AboutContent';

export default About;
