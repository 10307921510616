import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { Route } from 'react-router';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as PublicPages from 'pages/public';
import * as RestrictedPages from 'pages/restricted';
import { ErrorBoundary } from 'components';
import { Page } from './model';
import 'video-react/dist/video-react.css';
import { Grommet } from 'grommet';
import theme from 'theme';
import Toast from 'components/Toast';

export const PUBLIC_PAGES: Page[] = [
  {
    key: 'home',
    name: 'Home',
    url: '/',
    match: '/',
    exact: true,
  },
  {
    key: 'about',
    name: 'About me',
    url: '/about',
    match: '/about',
  },
  {
    key: 'wishlist',
    name: 'Wish list',
    url: '/wishlist',
    match: '/wishlist',
  },
  {
    key: 'preview',
    name: 'Preview',
    url: '/preview',
    match: '/preview',
  },
  {
    key: 'signup',
    name: 'Join',
    url: '/signup',
    match: '/signup',
  },
  {
    key: 'login',
    name: 'Login',
    url: '/login',
    match: '/login',
  },
];

export const RESTRICTED_PAGES: Page[] = [
  {
    key: 'memberHome',
    name: 'Member Home',
    url: '/member/home',
    match: '/member/home',
  },
  {
    key: 'about',
    name: 'About me',
    url: '/member/about',
    match: '/member/about',
  },
  {
    key: 'wishlist',
    name: 'Wish list',
    url: '/member/wishlist',
    match: '/member/wishlist',
  },
  {
    key: 'photos',
    name: 'Photos',
    url: '/member/photos',
    match: '/member/photos',
  },
  {
    key: 'videos',
    name: 'Videos',
    url: '/member/videos',
    match: '/member/videos',
  },
  {
    key: 'account',
    name: 'Account',
    url: '/member/account',
    match: '/member/account',
  },
];

const history = createBrowserHistory();

ReactDOM.render(
  <RecoilRoot>
    <ErrorBoundary>
      <Router history={history}>
        <Grommet full={true} theme={theme}>
          <Suspense fallback={<div>Loading...</div>}>
            <Toast>
              {/* public pages */}
              <Route exact={true} path="/" component={PublicPages.Home} />
              <Route path="/about" component={PublicPages.About} />
              <Route path="/wishlist" component={PublicPages.WishList} />
              <Route path="/preview" component={PublicPages.Preview} />
              <Route path="/signup" component={PublicPages.Signup} />
              <Route path="/login" component={PublicPages.Login} />
              <Route path="/reset" component={PublicPages.Reset} />
              {/* restricted pages */}
              <Route path="/member/home" component={RestrictedPages.Home} />
              <Route path="/member/about" component={RestrictedPages.About} />
              <Route path="/member/wishlist" component={RestrictedPages.WishList} />
              <Route exact={true} path="/member/photos" component={RestrictedPages.Galleries} />
              <Route path="/member/photos/:galleryId/:imageId?" component={RestrictedPages.Gallery} />
              <Route exact={true} path="/member/videos" component={RestrictedPages.Galleries} />
              <Route path="/member/videos/:videoId" component={RestrictedPages.Video} />
              <Route path="/member/account" component={RestrictedPages.Account} />
            </Toast>
          </Suspense>
        </Grommet>
      </Router>
    </ErrorBoundary>
  </RecoilRoot>,
  document.getElementById('app')
);
