import React from 'react';
import styled from 'styled-components';
import { Box, Layer } from 'grommet';

export type ToastType = 'SUCCESS' | 'ERROR';

export interface ToastProps {
  text: string;
  type: ToastType;
}

const Container = styled(Box)<{ type: ToastType }>`
  background: ${({ type }) => (type === 'SUCCESS' ? '#dfefdf' : '#ffdfdf')};
  padding: 16px;
  color: ${({ type }) => (type === 'SUCCESS' ? '#006800' : '#d80000')};
  border-radius: 3px;
  border: 1px solid #006800;
`;

const Toast: React.FC<ToastProps> = ({ text, type }) => {
  return (
    <Layer position="top" modal={false} margin={{ top: '100px' }} responsive={false} plain={true} animation="fadeIn">
      <Container elevation="large" type={type}>
        {text}
      </Container>
    </Layer>
  );
};

export default Toast;
