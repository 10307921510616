import { atom, useRecoilState, SetterOrUpdater, useRecoilValue, selector } from 'recoil';
import { User } from './model';
import { CountryDto, ContentType } from './dtos';
import { api } from 'services';
import { get } from 'lodash';

const countriesState = atom<CountryDto[]>({
  key: 'countries',
  default: api.getCountries(),
});

export const useCountries = (): CountryDto[] => useRecoilValue(countriesState);

const contentState = atom<ContentType>({
  key: 'content',
  default: api.getContent(),
});

export const useContent = (): ((path: string) => string) => {
  const content = useRecoilValue(contentState);
  return path => (content ? get(content, path) : '');
};

const textState = atom<Record<string, string>>({
  key: 'text',
  default: (async (): Promise<Record<string, string>> => {
    const response = await api.getText();
    return response.reduce((acc, value) => ({ ...acc, [value.key]: value.value }), {} as Record<string, string>);
  })(),
});

export const useText = (): ((path: string) => string) => {
  const content = useRecoilValue(textState);
  return path => (content ? get(content, path) : '') || path;
};

const tokenState = atom<string | undefined>({
  key: 'token',
  default: window.localStorage.getItem('token') || undefined,
});

export const useTokenState = (): [string | undefined, SetterOrUpdater<string | undefined>] =>
  useRecoilState(tokenState);

const userState = selector<User | undefined>({
  key: 'user',
  get: async ({ get }) => {
    const token = get(tokenState);
    if (token) {
      try {
        const dto = await api.getUser();
        return {
          active: dto.active,
          email: dto.email,
          nickname: dto.nickname,
          subscriptionType: dto.subscriptionType,
          expirationDate: dto.subscriptionExpiry,
        };
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  },
});

export const useUser = (): User | undefined => useRecoilValue(userState);
