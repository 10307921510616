import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Identicon } from '../../components';
import md5 from 'blueimp-md5';
import { useUser } from 'globalState';

interface Props {
  parentId?: number;
  onSend: (parentId: number, comment: string) => void;
  onCancel: () => void;
}

const Container = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled.div`
  flex-grow: 0;
  padding-right: 10px;
`;

const InputContainer = styled.div`
  flex-grow: 1;
`;

const Input = styled.textarea`
  border: 1px solid #666666;
  border-radius: 3px;
  width: 100%;
  height: 80px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  border: 1px solid #666666;
  border-radius: 10px;
  color: #666666;
  background-color: white;
  cursor: pointer;
  min-width: 60px;
  margin: 2px;
  &:hover {
    background-color: #efefef;
  }
  &:active {
    background-color: #dddddd;
  }
`;

const SendButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  border: 1px solid #666666;
  border-radius: 10px;
  color: white;
  background-color: #666666;
  cursor: pointer;
  min-width: 60px;
  margin: 2px;
  &:hover {
    background-color: #8b0606;
    border-color: #8b0606;
  }
  &:active {
    background-color: #740505;
    border-color: #740505;
  }
`;

const CommentInput: React.FC<Props> = props => {
  const user = useUser();
  const [comment, setComment] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  });

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onCancel();
  };

  const onSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onSend(props.parentId as number, comment);
  };

  const emailHash = md5((user && user.email) || '');

  return (
    <Container>
      <AvatarContainer>
        <Identicon hash={emailHash} />
      </AvatarContainer>
      <InputContainer>
        <Input ref={inputRef} value={comment} onChange={e => setComment(e.target.value)} />
        <ButtonContainer>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <SendButton onClick={onSend}>Send</SendButton>
        </ButtonContainer>
      </InputContainer>
    </Container>
  );
};

export default CommentInput;
