import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-family: 'Lora', Serif;
  color: white;
  text-align: center;
`;

const Container = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const OfferContainer = styled.div`
  width: 240px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 16px;
  background-image: linear-gradient(rgb(208, 208, 208), rgb(168, 168, 168));
`;

const InfoContainer = styled.div`
  height: 200px;
`;

const Title = styled.h1`
  font-family: 'Lora', Serif;
  font-weight: bold;
  color: #8b0606;
`;

const Price = styled.div`
  font-family: 'Lora', Serif;
  font-weight: bold;
  font-size: 32px;
`;

const PricePerMonth = styled.div`
  font-family: 'Lora', Serif;
  font-size: 14px;
`;

const BuyButton = styled.button`
  font-family: 'Lora', Serif;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  width: 175px;
  height: 40px;
  color: white;
  background-image: linear-gradient(rgb(105, 105, 105), rgb(66, 66, 66));
  #linear-gradient(rgb(139, 6, 6), rgb(74, 0, 0));
  cursor: pointer;
  margin-bottom: 25px;
  &:hover {
    background-image: linear-gradient(rgb(139, 6, 6), rgb(74, 0, 0));
  }
`;

const Component: React.FC = () => {
  return (
    <>
      <Text>You don't have an active subscription.</Text>
      <Text>To get access to all videos and picture sets choose one of the plans below.</Text>
      <Container>
        <OfferContainer>
          <Title>1 Month</Title>
          <InfoContainer>
            <Price>&euro; 29.-</Price>
          </InfoContainer>
          <BuyButton>Buy Now</BuyButton>
        </OfferContainer>
        <OfferContainer>
          <Title>3 Months</Title>
          <InfoContainer>
            <Price>&euro; 69.-</Price>
            <PricePerMonth>(&euro; 23.- / month)</PricePerMonth>
          </InfoContainer>
          <BuyButton>Buy Now</BuyButton>
        </OfferContainer>
        <OfferContainer>
          <Title>1 Year</Title>
          <InfoContainer>
            <Price>&euro; 199.-</Price>
            <PricePerMonth>(&euro; 16.58 / month)</PricePerMonth>
          </InfoContainer>
          <BuyButton>Buy Now</BuyButton>
        </OfferContainer>
      </Container>
    </>
  );
};

Component.displayName = 'NoSubscription';

export default Component;
