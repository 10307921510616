import React, { useEffect } from 'react';
import { RESTRICTED_PAGES } from '../..';
import { NavBar, ActionButton, NavBarLink } from '../../components';
import BaseLayout from '../baselayout';
import { renewToken } from 'util/auth';
import styled from 'styled-components';
import { useContent, useTokenState } from 'globalState';
import { useHistory } from 'react-router';

interface Props {
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;

const LogoImage = styled.img`
  width: 300px;
  height: 85px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledNavBar = styled(NavBar)`
  height: 48px;
`;

const ActionContainer = styled.div`
  height: 48px;
  margin-left: 20px;
`;

const Layout: React.FC<Props> = props => {
  const content = useContent();
  const [, setToken] = useTokenState();
  const { push } = useHistory();

  useEffect(() => {
    renewToken();
  }, []);

  const goToHome = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    push('/member/home');
  };

  const doLogout = () => {
    window.localStorage.clear();
    setToken(undefined);
    push('/');
  };

  const header = () => (
    <Container>
      <Logo onClick={goToHome}>
        <LogoImage src="/images/logo.svg" />
      </Logo>
      <Spacer />
      <StyledNavBar pages={RESTRICTED_PAGES}>
        <NavBarLink href={content('url.escort')} target="_blank">
          Escort
        </NavBarLink>
      </StyledNavBar>
      <ActionContainer>
        <ActionButton value="Logout" onClick={doLogout} />
      </ActionContainer>
    </Container>
  );

  return <BaseLayout header={header}>{props.children}</BaseLayout>;
};

export default Layout;
