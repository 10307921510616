import React from 'react';
import { PUBLIC_PAGES } from '../..';
import { NavBar, NavBarLink } from '../../components';
import BaseLayout from '../baselayout';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useContent } from 'globalState';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;

const LogoImage = styled.img`
  width: 300px;
  height: 85px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledNavBar = styled(NavBar)`
  height: 48px;
`;

const Layout: React.FC = ({ children }) => {
  const { push } = useHistory();
  const content = useContent();

  const goToHome = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    push('/');
  };

  const header = () => (
    <Container>
      <Logo onClick={goToHome}>
        <LogoImage src="/images/logo.svg" />
      </Logo>
      <Spacer />
      <StyledNavBar pages={PUBLIC_PAGES}>
        <NavBarLink href={content('url.escort')} target="_blank">
          Escort
        </NavBarLink>
      </StyledNavBar>
    </Container>
  );
  return <BaseLayout header={header}>{children}</BaseLayout>;
};

export default Layout;
