import React from 'react';
import styled from 'styled-components';
import { NavButton } from '../Button';
import { Page } from '../../model';
import { useHistory } from 'react-router';

const Container = styled.div`
  height: 48px;
  display: flex;
`;

const NavContainer = styled.div`
  flex-grow: 1;
`;

const ActionContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const NavBarLink = styled.a`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  text-align: center;
  border-radius: 16px;
  color: white;
  background-image: linear-gradient(#555555, #2a2a2a);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 80px;
  outline: none;
  text-decoration: none;
  display: inline-block;
  &:hover {
    background-image: linear-gradient(#8b0606, #4a0000);
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

interface Props {
  className?: string;
  pages: Page[];
  children?: React.ReactNode;
}

const NavBar: React.FC<Props> = ({ pages, className, children }) => {
  const { push, location } = useHistory();

  const buttons: JSX.Element[] = [];
  pages.forEach((p, i) =>
    buttons.push(
      <NavButton
        key={p.key}
        value={p.name}
        onClick={() => push(p.url)}
        isFirst={i === 0}
        isLast={i === pages.length - 1}
        selected={p.exact ? location.pathname === p.match : location.pathname.startsWith(p.match)}
      />
    )
  );
  return (
    <Container className={className}>
      <NavContainer>
        <nav>{buttons}</nav>
      </NavContainer>
      <ActionContainer>{children}</ActionContainer>
    </Container>
  );
};

export default NavBar;
